<script>
import { compareAsc, add, sub, intervalToDuration } from "date-fns";

export default {
  props: {
    value: {
      type: Object,
    },

    label: {
      type: String,
      default: "",
    },

    maxDate: {
      type: Date,
    },

    isShowButtonReset: {
      type: Boolean,
      default: true,
    },

    isMonthRange: {
      type: Boolean,
      default: false,
    },

    background: {
      type: String,
      default: "grey",
    },
  },

  data() {
    return {
      range: null,
      masks: {
        input: "DD.MM.YYYY",
      },

      availableDates: {
        start: null,
        end: new Date(),
      },

      // modelConfig: {
      //   type: "string",
      //   mask: "DD-MM-YYYY", // Uses 'iso' if missing
      // },

      attribute: {
        highlight: {
          start: {
            style: {
              backgroundColor: "#FFDA7A",
            },
            contentStyle: {
              color: "#212121",
            },
          },
          base: {
            style: {
              backgroundColor: "#FFE5A2",
            },
          },
          end: {
            style: {
              backgroundColor: "#FFDA7A",
            },
            contentStyle: {
              color: "#212121",
            },
          },
        },
      },
    };
  },

  watch: {
    range: {
      deep: true,
      handler() {
        if (
          compareAsc(
            new Date(this.value?.start),
            new Date(this.range?.start)
          ) !== 0 ||
          compareAsc(new Date(this.value?.end), new Date(this.range?.end)) !== 0
        ) {
          this.$emit("input", this.range);
        }
      },
    },

    value: {
      deep: true,
      handler(newValue) {
        this.range = newValue;
      },
    },
  },

  computed: {
    isVisibleResetButton() {
      return (
        this.range !== null &&
        this.range?.start !== "" &&
        this.range?.end !== "" &&
        this.isShowButtonReset
      );
    },
  },

  methods: {
    resetRange() {
      this.range = null;
    },

    handleDayClick(event) {
      if (this.isMonthRange) {
        let endDate;
        const interval = intervalToDuration({
          start: event.date,
          end: new Date(),
        });

        if (
          interval.years > 0 ||
          interval.months > 0 ||
          (interval.months === 1 && interval.days > 0)
        ) {
          endDate = add(event.date, {
            months: 1,
          });
        } else {
          endDate = new Date();
        }

        this.availableDates = {
          start: sub(event.date, {
            months: 1,
          }),
          end: endDate,
        };
      }
    },

    popoverDidHide() {
      if (this.isMonthRange) {
        this.availableDates = {
          start: null,
          end: new Date(),
        };
      }
    },
  },
};
</script>

<template>
  <div class="main-date-range">
    <v-date-picker
      v-model="range"
      mode="date"
      :masks="masks"
      :drag-attribute="attribute"
      :select-attribute="attribute"
      :max-date="maxDate"
      :available-dates="availableDates"
      is-range
      @dayclick="handleDayClick"
      @popoverDidHide="popoverDidHide"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <p v-if="label" class="main-date-range__label">{{ label }}</p>

        <div class="main-date-range__input-wrapper">
          <div
            class="main-date-range__input"
            :class="`main-date-range__input_background_${background}`"
          >
            <input
              v-maska="'##.##.####'"
              :value="inputValue.start"
              placeholder="__.__.____"
              readonly
              v-on="inputEvents.start"
            />
            <span class="main-date-range__icon-calendar"></span>
          </div>

          <span class="main-date-range__icon-arrow"></span>

          <div
            class="main-date-range__input"
            :class="`main-date-range__input_background_${background}`"
          >
            <input
              v-maska="'##.##.####'"
              :value="inputValue.end"
              placeholder="__.__.____"
              readonly
              v-on="inputEvents.end"
            />
            <span class="main-date-range__icon-calendar"></span>
          </div>

          <button
            v-if="isVisibleResetButton"
            class="main-date-range__button"
            @click="resetRange"
          >
            <span></span>
            Сбросить
          </button>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<style lang="scss" scoped>
.main-date-range {
  &__label {
    @include caption-1;
    color: $dark-sixth;
    margin-bottom: $space-s;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
  }

  &__input {
    position: relative;

    input {
      height: 48px;
      max-width: 150px;
      min-width: 150px;
      width: 100%;
      padding: 0 $space-m;
      border-radius: $space-s;
      border: 1px solid $light-fifth;
      background-color: $light-second;
      outline: none;
    }

    &_background {
      &_white {
        input {
          background-color: $light-primary;
          color: $dark-fifth;
        }
      }
    }
  }

  &__icon-arrow {
    mask: url("../../assets/icons/arrow_right.svg") 0 / cover no-repeat;
    min-width: 24px;
    height: 24px;
    display: inline-block;
    background: $dark-sixth;
    margin: 0 $space-s;
  }

  &__icon-calendar {
    mask: url("../../assets/icons/calendar.svg") 0 / cover no-repeat;
    min-width: 16px;
    height: 16px;
    display: inline-block;
    background: $light-sixth;
    position: absolute;
    right: $space-m;
    top: $space-m;
  }

  &__button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    @include text-2;
    color: $dark-fifth;
    display: flex;
    align-items: center;
    margin-left: $space-l;
    transition: color 0.1s linear;

    span {
      mask: url("../../assets/icons/close_bold.svg") 0 / cover no-repeat;
      min-width: 24px;
      height: 24px;
      display: inline-block;
      background: $dark-fifth;
      margin-right: $space-s;
      transition: background 0.1s linear;
    }

    &:hover {
      color: $dark-primary;

      span {
        background: $dark-primary;
      }
    }

    &:active {
      color: $dark-fourth;

      span {
        background: $dark-fourth;
      }
    }
  }
}

@media (max-width: 576px) {
  .main-date-range {
    &__icon-arrow {
      margin: 0 4px;
    }

    &__input {
      input {
        min-width: auto;
      }
    }
  }
}
</style>
